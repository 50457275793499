import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExternalLinkAlt, faArrowRight } from '@fortawesome/free-solid-svg-icons';
import { useLocation } from 'react-router-dom';
import { useEffect } from 'react';
import { useMediaQuery } from '../../hooks/use-media-query';

const experiences = [
    {
        title: 'Frontend Engineer, Accessibility',
        company: 'Symplicity',
        startDate: '2022',
        endDate: 'Present',
        description:
            'Building screens for people that have disabilities, with the focus in accessibility. Working mainly with Angular, React to deliver products to large colleges in the USA and around the world such as Harvard, MIT, Yale, and others. Also, I work with WCAG 2.0 and 2.1 to ensure that the products are accessible to everyone.',
        skills: ['JavaScript', 'TypeScript', 'Angular', 'React', 'HTML', 'CSS', 'JEST', 'W3C Standart', 'WCAG', 'PHP', 'RxJS', 'AWS'],
        url: 'https://www.symplicity.com',
    },
    {
        title: 'Fullstack Engineer',
        company: 'MAIS - Vendas e Serviços',
        startDate: '2020',
        endDate: '2022',
        description:
            'Worked on medium to large-scale projects, on the MAIS Platform, with the focus on e-commerce. Developed and maintained the frontend and backend for large companies such as Loreal Paris, C&A, Multilaser, Estácio, CIMED, and others. Worked with integrations such as VTEX, SalesForce, MercadoPago and Strapi API.',
        skills: ['JavaScript', 'TypeScript', 'Angular', 'React', 'HTML', 'SCSS/LESS', 'MongoDB', 'Node.js', 'PostgreSQL', '.NET Core', 'AWS', 'JEST'],
        url: 'https://www.mais.com.br',
    },
    {
        title: 'Frontend Engineer',
        company: 'Hospital Universitário Alzira Velano',
        startDate: '2018',
        endDate: '2020',
        description:
            'Using tecnologies like HTML, CSS, JavaScript, JQuery, Bootstrap and Node.js to develop and maintain the hospital website. Also, I was responsible for the development of the new screens and the restructuring of the website layout.',
        skills: ['JavaScript', 'TypeScript', 'HTML', 'CSS', 'Bootstrap', 'Node.js', 'JQuery', 'SQLServer'],
        url: 'https://www.huav.com.br',
    },
];

export const Experience = () => {
    const location = useLocation();

    useEffect(() => {
        if (location.hash === '#experience') {
            const element = document.getElementById('experience');
            element.scrollIntoView({ behavior: 'smooth' });
        }
    }, [location]);

    const huge = useMediaQuery('(min-width: 1024px)');
    const medium = useMediaQuery('(min-width: 640px)');

    return (
        <div
            className={`mt-12 ${huge ? 'p-3' : 'py-5 px-0'}`}
            id="experience"
            aria-label="Experience">
            {!huge && <h2 className="fs-5 fw-semibold text-white text-uppercase mb-3">Experience</h2>}
            <ul className="d-flex flex-column list-style-none ps-0">
                {experiences &&
                    experiences.map((experience, index) => (
                        <li key={`experience_${index}`}>
                            <a
                                target="_blank"
                                rel="noreferrer noopener"
                                className={`transition-all py-3 block d-flex mb-5 ${medium ? 'flex-row' : 'flex-column'}`}
                                href={experience.url}>
                                <header className="flex-1 min-width-xs-tiny">
                                    <span className="vertical-top text-uppercase common-text-color fs-px-2 fw-semibold">
                                        {experience.startDate} - {experience.endDate}
                                    </span>
                                </header>
                                <div className="d-flex flex-column flex-3">
                                    <h3 className="block_title fs-px-3">
                                        {experience.title} - {experience.company} <FontAwesomeIcon icon={faExternalLinkAlt} />
                                    </h3>
                                    <p className="lh-sm fs-px-3">{experience.description}</p>
                                    <ul className="flex-wrap d-flex list-style-none ps-0 justify-content-start">
                                        {experience.skills.map((skill, index) => (
                                            <li key={`skills_${index}`}>
                                                <div className="skills_content mb-3 py-1 px-2 f2-medium rounded-pill fs-px-3 lh-sm me-2">{skill}</div>
                                            </li>
                                        ))}
                                    </ul>
                                </div>
                            </a>
                        </li>
                    ))}
            </ul>
            <div className={`view-full external-link-text d-flex align-items-center fs-5 ${huge ? 'p-3' : 'py-5 px-0'}`}>
                <a
                    className="transition-all cursor-pointer"
                    href="/resume.pdf"
                    target="_blank"
                    rel="noopener noreferrer">
                    View full resumé
                    <FontAwesomeIcon icon={faArrowRight} />
                </a>
            </div>
        </div>
    );
};
