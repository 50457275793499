import React from 'react';
import ReactDOM from 'react-dom/client';
import './styles.scss';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { Home } from './templates/Home';
import { NotFound } from './components/NotFound';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Archive } from './components/Archive';
import { useMediaQuery } from './hooks/use-media-query';

const App = () => {
    const huge = useMediaQuery('(min-width: 1024px)');

    return (
        <div className={`max-width-large mx-auto py-12 d-flex vw-100 justify-content-between ${huge ? 'px-12' : 'px-4'}`}>
            <BrowserRouter>
                <Routes>
                    <Route
                        path="/"
                        element={<Home />}
                    />
                    <Route
                        path="/archive"
                        element={<Archive />}
                    />
                    <Route
                        path="*"
                        element={<NotFound />}
                    />
                </Routes>
            </BrowserRouter>
        </div>
    );
};

const rootElement = document.getElementById('root');
if (rootElement) {
    const root = ReactDOM.createRoot(rootElement);
    root.render(
        <React.StrictMode>
            <App />
        </React.StrictMode>
    );
}

