import { useLocation } from 'react-router-dom';
import { useEffect } from 'react';
import { useMediaQuery } from '../../hooks/use-media-query';

const paragraphs = [
    `As a dedicated developer, I stay abreast of the latest technologies, consistently seeking opportunities for growth. During my tenure in the marketing department of Hospital Universitário Alzira Velano, I led the overhaul of the website's layout and backend, significantly enhancing both its performance and design.`,
    `Subsequently, I transitioned to working on medium to large-scale projects within the MAIS Platform, prioritizing clean and well-structured front-end code for optimal efficiency and usability.`,
    `My current role at Symplicity as a Frontend Developer underscores my commitment to accessibility, particularly evident in my contributions to a website catering to the diverse needs of students in prestigious U.S. institutions like Harvard, MIT, and Yale.`,
];

export const About = () => {
    const location = useLocation();

    useEffect(() => {
        if (location.hash === '#about') {
            const element = document.getElementById('about');
            element.scrollIntoView({ behavior: 'smooth' });
        }
    }, [location]);

    const huge = useMediaQuery('(min-width: 1024px)');

    return (
        <div
            className={`${huge ? 'p-3' : 'py-5 px-0'}`}
            id="about"
            aria-label="About">
            {!huge && <h2 className="fs-5 fw-semibold text-white text-uppercase mb-3">About</h2>}
            {paragraphs &&
                paragraphs.map((paragraph, index) => (
                    <p
                        key={index}
                        dangerouslySetInnerHTML={{ __html: paragraph }}></p>
                ))}
        </div>
    );
};
