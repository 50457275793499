import './styles.scss';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGithub, faLinkedin } from '@fortawesome/free-brands-svg-icons';

export const SocialMedia = () => {
    const socialMedia = [
        { name: 'GitHub', url: 'https://github.com/AntonioBrasilio', icon: faGithub },
        { name: 'LinkedIn', url: 'https://www.linkedin.com/in/antonio-bras%C3%ADlio-3b511b183/', icon: faLinkedin },
    ];

    return (
        <div
            className="position-absolute bottom-0"
            id="socialmedia">
            <div>
                <ul
                    className="d-flex list-style-none ps-0"
                    aria-label="Social Media">
                    {socialMedia &&
                        socialMedia.map((media, index) => (
                            <li
                                className="transition-all social-media-icons me-4 fs-4"
                                key={index}>
                                <a
                                    href={media.url}
                                    target="_blank"
                                    rel="noreferrer">
                                    <FontAwesomeIcon icon={media.icon} />
                                </a>
                            </li>
                        ))}
                </ul>
            </div>
        </div>
    );
};
